import React, { useState, useEffect } from 'react';
import Barometer from 'components/ui/barometre';
import { useHistory, NavLink, useParams } from 'react-router-dom';

function Dashboard() {
  //let { id } = useParams();
  const [token, setToken] = useState(null);
  const [commID, setCommid] = useState(1);
  const [random, setRandom] = useState('');
  const [Loading, setLoading] = useState();
  const [msg, setMsg] = useState(
    <button disabled type='button' className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 inline-flex items-center'>
      <svg role='status' className='inline mr-3 w-4 h-4 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='#E5E7EB' />
        <path
          d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
          fill='currentColor'
        />
      </svg>
      Loading...
    </button>
  );
  useEffect(() => {
    const dongu = setInterval(() => {
      if (token !== null) {
        setMsg('');
      }
    }, 2100);
    if (!localStorage.getItem('user-token')) {
      history.push('/');
    } else {
      
      setToken('Bearer' + ' ' + localStorage.getItem('user-token'));
    }
  });

  useEffect(() => {
    const dongu = setInterval(() => {
      setRandom(0);
      if (!localStorage.getItem('user-token')) {
        history.push('/');
      } else {
        setToken('Bearer' + ' ' + localStorage.getItem('user-token'));
        DashboardList(commID);
        CommList();
      }
    }, 2000);
    return () => {
      clearInterval(dongu);
    };
  });
  async function logOut() {
    localStorage.clear();
  }
  const history = useHistory();
  const [receivers, setReceivers] = useState([]);
  const [comms, setComms] = useState([]);
  const url = 'https://comm.a-traq.com';

  const id = '1';
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: token },
    body: null,
  };
  async function DashboardList(id) {
    let result = await fetch(url + '/api/Receivers/GetReceiverList?CommId='+id, requestOptions);
    let isJson = result.headers.get('content-type')?.includes('application/json');
    let dataITems = isJson && (await result.json());

    //console.log(dataITems.data);

    //let receiverArray = [];
    if (!result.ok) {
      // get error message from body or default to response status
      let error = (dataITems && dataITems.errorDescription) || result.status;
      logOut();
      return alert('Hata kodu: ' + error + ' Token Hatalı: ' + token);
    }
    setReceivers(dataITems.data);
    //console.log(dataITems.data[0]);
    //console.log(token, receiverArray, baroArray);

    //console.log(JSON.stringify(dataITems.data[1]), title, finalItems, token);
    //localStorage.setItem('list', JSON.stringify(dataITems.data[0]));
  }
  async function CommList() {
    let result2 = await fetch(url + '/api/Communicator/GetCommunicatorList', requestOptions);
    let isJson2 = result2.headers.get('content-type')?.includes('application/json');
    let dataITems2 = isJson2 && (await result2.json());
    //let receiverArray = [];

    //console.log(dataITems2.data);
    if (!result2.ok) {
      // get error message from body or default to response status
      let error2 = (dataITems2 && dataITems2.errorDescription) || result2.status;
      logOut();
      return alert('Hata kodu: ' + error2 + ' Token Hatalı: ' + token);
    }
    setComms(dataITems2.data);
  }

  //console.log(receivers);
  return (
    <div className='block w-10/12 h-full justify-center items-center bottom-0 p-2 overflow-y-auto'>
      <div className='flex flex-col w-full justify-start items-center content-center mx-auto h-full  text-xs sm:text-sm '>
        <div className='flex flex-row justify-start items-center content-start py-4 px-4 w-full'>
          <select onChange={e => (setCommid(e.target.value),DashboardList(e.target.value))}  id="default" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block   p-2.5 dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 w-1/3 ">
            {comms &&
            comms.map((comm, index) => (
            <option  key={index}  selected={(index === 0 ? 'defaultValue' : '')} value={comm.id} > {comm.name} - {comm.ipAdress}</option>
            ))}
          </select>
          <button onClick={''} className='group ml-3 inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-green-600 text-white hover:text-slate-100 hover:bg-green-500 active:bg-green-800 active:text-green-100 focus-visible:outline-green-600 w-24'>
                <span>Add</span>
          </button>
          <button onClick={''} className='group ml-3 inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-red-600 text-white hover:text-slate-100 hover:bg-red-500 active:bg-red-800 active:text-red-100 focus-visible:outline-red-600 w-24'>
                <span>Delete</span>
          </button>
        </div>
          
        {msg}
        <div className='flex flex-wrap items-center content-center justify-center w-full md:max-w-2xl lg:max-w-4xl xl:max-w-5xl'>
          {receivers &&
            receivers.map((item, index) => (
              <div key={index} className={'' === item.name ? ' py-6 px-2 dials flex opacity-50' : 'px-2 py-6 dials flex'}>
                <Barometer id='dial9' value={(item.detail === null ? null : item.detail.partitionCount) === null ? random : item.detail.partitionCount} title={item.name} max={(item.detail === null ? 0 : item.detail.maxPartitions) === 0 || item.detail.maxPartitions === null ? 5000 : item.detail.maxPartitions} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
