//import {VscHeart} from 'react-icons/vsc';
//import {BsBag} from 'react-icons/bs'
//import {IoIosSearch} from 'react-icons/io';
//import {FaShippingFast} from 'react-icons/fa';
import React from 'react';
import { NavLink, Switch, Route, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

function Header() {
  //let { id } = useParams();
  function top() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  async function logOut() {
    localStorage.clear();
  }
  const [isActive, setActive] = useState('false');
  const [userName, setUsername] = useState('');
  const handleToggle = () => {
    setActive(!isActive);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    console.log(isActive);
  };

  useEffect(() => {
    const dongu2 = setInterval(() => {
      setUsername(localStorage.getItem('user-name'));
    }, 2000);
    return () => {
      clearInterval(dongu2);
    };
  }, []);

  return (
    <div className='w-full h-full bg-header justify-center text-white flex py-2 px-6 z-50 '>
      <div className='container flex flex-row justify-between '>
        <Switch>
          <Route exact path='/'>
            <img className='sm:h-16 lg:h-24' src='https://static.wixstatic.com/media/3fd0e2_2d7032d06f024a5390e2f7dc152ecf02~mv2.jpg/v1/fill/w_175,h_101,al_c,q_80,usm_0.66_1.00_0.01/3fd0e2_2d7032d06f024a5390e2f7dc152ecf02~mv2.webp' alt='logo' />
          </Route>
          <Route path='/dashboard'>
            <img className='sm:h-14 lg:h-20' src='logo-min.png' alt='logo' />
            <div className=' flex flex-col justify-end items-center content-center pl-1'>
              <span className='text-sm text-gray-800 font-normal  bg-[#d1e051] py-2 px-8 rounded-xl'>Hello {userName}</span>
              <button className='hover:text-yellow-500 w-full p-1 transition-colors' onClick={() => logOut()} title='Sign Out' data-logout-msg='You can improve your security further after logging out by closing this opened browser'>
                Log Out <i className='fa fa-sign-out '></i>
              </button>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default Header;
