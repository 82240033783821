//import { useState } from "react";
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

function Login() {
  const [userName, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [info, setInfo] = useState('');
  const history = useHistory();
  const [Loading, setLoading] = useState();
  const [err, setErr] = useState(null);
  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      // 👇️ your logic here
      console.log('Enter key pressed ✅');
      loginUser();
    }
  };
  useEffect(() => {
    if (localStorage.getItem('user-token') && localStorage.getItem('error') === '200') {
      history.push('/dashboard/home');
    } else {
      //setLoading('');
    }
  }, []);
  const url = 'https://comm.a-traq.com';
  let item = { userName, password };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(item),
  };
  async function loginUser() {
    console.log(userName, password);
    if (userName !== '' && password !== '') {
      let result = await fetch(url + '/api/Login/GetToken', requestOptions);
      let isJson = result.headers.get('content-type')?.includes('application/json');
      let dataITems = isJson && (await result.json());
      localStorage.setItem('error', result.status);
      console.log(dataITems.token);

      if (!result.ok) {
        // get error message from body or default to response status
        let error = (dataITems && dataITems.errorDescription) || result.status;
        return (
          setErr(error),
          setInfo(
            <>
              <div id='toast-warning' className='flex items-center  -mt-16 p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800' role='alert'>
                <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200'>
                  <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z' clip-rule='evenodd'></path>
                  </svg>
                  <span className='sr-only'>Warning icon</span>
                </div>
                <div className='ml-3 text-xs font-normal'>{dataITems.errorDescription || result.status}.</div>
                <button onClick={() => (setInfo(''), setLoading(''))} type='button' className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700' data-dismiss-target='#toast-warning' aria-label='Close'>
                  <span className='sr-only'>Close</span>
                  <svg aria-hidden='true' clasNames='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                    <path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd'></path>
                  </svg>
                </button>
              </div>
            </>
          ),
          setTimeout(() => (setInfo(''), setLoading('')), 100000)
        );
      } else {
        setInfo(
          <div id='toast-success' className='flex items-center -mt-16 p-4 mb-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800' role='alert'>
            <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200'>
              <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd'></path>
              </svg>
              <span className='sr-only'>Check icon</span>
            </div>
            <div className='ml-3 text-sm font-normal'>Login successfully.</div>
            <button onClick={() => setInfo('')} type='button' className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700' data-dismiss-target='#toast-success' aria-label='Close'>
              <span className='sr-only'>Close</span>
              <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd'></path>
              </svg>
            </button>
          </div>
        );
        localStorage.setItem('user-token', dataITems.token);
        localStorage.setItem('user-name', dataITems.username);
        localStorage.setItem('user-id', dataITems.id);
        localStorage.setItem('user-roles', dataITems.roles);

        setTimeout(() => history.push('/dashboard/home'), 2000);
        setLoading(
          <svg role='status' className='inline mr-3 w-4 h-4 text-white animate-spin' viewBox='0 0 100 101' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z' fill='#E5E7EB' />
            <path
              d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
              fill='currentColor'
            />
          </svg>
        );
      }
    } else {
      console.log('UserName ve Password Boş');
      setInfo(
        <>
          <div id='toast-warning' className='flex items-center -mt-16 p-4 w-full max-w-xs text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800' role='alert'>
            <div className='inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200'>
              <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' d='M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z' clip-rule='evenodd'></path>
              </svg>
              <span className='sr-only'>Warning icon</span>
            </div>
            <div className='ml-3 text-xs font-normal'>UserName and Password can't be blank.</div>
            <button onClick={() => (setInfo(''), setLoading(''))} type='button' className='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700' data-dismiss-target='#toast-warning' aria-label='Close'>
              <span className='sr-only'>Close</span>
              <svg aria-hidden='true' clasNames='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path fill-rule='evenodd' d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z' clip-rule='evenodd'></path>
              </svg>
            </button>
          </div>
        </>
      );
      setTimeout(() => (setInfo(''), setLoading('')), 4000);
    }
  }

  return (
    <div className='flex pb-[3rem] h-full justify-center items-center '>
      <div className='container max-w-[75%] mx-12  h-[90%] bg-white rounded-md opacity-70'>
        <div className='row-span-1 p-6'>
          <div className=' w-2/3 flex justify-center'>
            <img src='https://identity.a-traq.com/assets/logo.png' alt='' />
          </div>
        </div>
        <div className='row-span-2 flex flex-row px-6 h-1/3 jus'>
          <div className=' w-2/3 flex flex-col justify-start pr-6'>
            <h1 className='text-left w-full font-semibold text-3xl'>A-Traq Communicator</h1>
            <p className='text-sm'>This page is for A-Traq users to maintain their software and to keep the system up-to-date. If you don't have access please contact your local ATraq representative today.</p>
          </div>
          <div className='  w-1/3 px-3'>
            {info}
            <h1 className='text-center w-full font-semibold text-3xl mb-4'>Please sign in</h1>
            <input onKeyDown={handleKeyDown} onChange={e => setUser(e.target.value)} className='block w-full mb-3 appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm' type='text' name='userName' required="Can't be blank" placeholder='Username' />
            <input onKeyDown={handleKeyDown} onChange={e => setPassword(e.target.value)} className='block w-full mb-3 appearance-none rounded-md border border-gray-200 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:border-blue-500 focus:bg-white focus:outline-none focus:ring-blue-500 sm:text-sm' type='password' name='password' required="Can't be blank" placeholder='Password' />
            <button onClick={loginUser} className='group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-blue-600 text-white hover:text-slate-100 hover:bg-blue-500 active:bg-blue-800 active:text-blue-100 focus-visible:outline-blue-600 w-full'>
              {Loading}
              <span>
                Sign in <span aria-hidden='true'>→</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
