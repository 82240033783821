import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Footer from 'components/footer';
import Login from './login';
import Dashboard from 'components/dashboard';
import Navigator from 'components/nav';
import LoginVideo from './login.mp4';
//import UserInfo from 'components/ui/userinfo'
//import {useState, useEffect} from 'react'
//import { NavLink } from 'react-router-dom';

function Content() {
  return (
    <main className='flex h-full w-full 2xl:container justify-center content-center items-center flex-row'>
      <video autoPlay loop muted id='video'>
        <source src={LoginVideo} type='video/mp4' />
      </video>
      <Switch>
        <Route exact path='/'>
          <Login />
          <Footer />
        </Route>
        <Route path='/dashboard'>
          <Navigator />
          <Route path='/dashboard/home' children={<Dashboard />} />
          <Route path='/dashboard/Advanced-settings'>
            <h1 className='w-10/12 text-center text-white'>Advanced Settings</h1>
          </Route>
          <Footer />
        </Route>
      </Switch>
    </main>
  );
}

export default Content;
