//import { useWindowWidth } from '@react-hook/window-size'
//import Nav from "components/nav";
//import Produclist from "components/produclist";
//import HeroSection from "components/HeroSection";
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
//import { BrowserRouter as Router} from "react-router-dom";
//import Footer from 'components/footer';
import Header from 'components/header';

import Content from 'components/content';
import { useState, useEffect } from 'react';
import { MainContext } from 'context';


function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  //const scrollDiv = document.querySelector(".content");
  // ... register
  //window.addEventListener('scroll', scrollDiv, {passive: false});

  const [proActive, setproActive] = useState('true');

  const [theme, setTheme] = useState('light');

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const data = {
    theme,
    setTheme,
    proActive,
    setproActive,
  };

  //const width = useWindowWidth()
  return (
    <Router>
      <MainContext.Provider value={data}>
        <div className='flex fixed sm:relative z-50 h-auto mx-auto  md:w-screen '>
          <Header />
        </div>

        <div className='h-full sm:h-full mx-auto items-center justify-center flex space-y-3 w-full transition-all'>
          <Content />
        </div>
      </MainContext.Provider>
    </Router>
  );
}

export default App;
