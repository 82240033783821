import { useEffect, useState } from 'react';
import React from 'react';
import { NavLink } from 'react-router-dom';
//import Slider from "react-slick";

function Navigator() {
  function top() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    //
  }, []);
  const settings = 'Advanced Settings';

  return (
    <div className='w-1/6 h-full flex flex-col justify-start content-start pt-4 pb-1 px-2 lg:px-4 text-white text-sm bg-gray-900 bg-opacity-70 transition-all animate__slow animate__animated animate__fadeIn'>
      <NavLink to={'/dashboard/home'} className='hover:text-red-600 w-full py-2 transition-colors'>
        Home
      </NavLink>
      <NavLink to={`/dashboard/${settings.replace(' ', '-')}`} className='hover:text-red-600 w-full py-2 transition-colors'>
        {' '}
        {settings}{' '}
      </NavLink>
    </div>
  );
}
//`/${category.link='product'}`
export default Navigator;

//{categories && categories.map((category, index) => <Category categoriID={categoriID} key={index} category={category} /> )}
