import React from 'react';
import { Chart } from 'react-google-charts';

const styles = {
  dial: {
    width: `auto`,
    height: `auto`,
    color: '#000',
    padding: '2px',
  },
  title: {
    fontSize: '1em',
    color: '#000',
  },
};



const Barometer = ({ id, value, title, max }) => {
  return (
    <div style={styles.dial}>
      <Chart
        height={160}
        chartType='Gauge'
        loader={<div></div>}
        data={[
          ['Label', 'Value'],
          [title, Number(value)],
        ]}
        options={{
          redFrom: max * 0.8,
          redTo: max,
          yellowFrom: max * 0.5,
          yellowTo: max * 0.8,
          minorTicks: 5,
          min: 0,
          max: max,
        }}
      />
    </div>
  );
};

export default Barometer;
