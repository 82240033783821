import { FaFacebookF } from 'react-icons/fa';
import { RiInstagramLine } from 'react-icons/ri';
import { GrTwitter } from 'react-icons/gr';
import React from 'react';

function Footer() {
  return (
    <div className='absolute block w-full justify-center items-center h-11 bottom-0 bg-gray-200 p-2 '>
      <div className='flex w-full justify-center items-center content-center mx-auto h-full  text-xs sm:text-sm '>
        <h1 className='text-center w-full'>© 2002 - 2022 Comon Professionals Pty. Ltd.</h1>
      </div>
    </div>
  );
}

export default Footer;
